import * as React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Container, Row, Col, Form, Button } from 'react-bootstrap';

import shield from "../images/noun-shield-4270007.svg"
import gearIcon from "../images/noun-service-1318655.svg"

const IndexPage = () => (
  <Layout>
    <Seo title="Dump Truck Services in Savannah & Beyond." />
    <Container className="py-5 singleCol">
      <Row className="g-5 g-lg-0">
        <Col lg={6}>
          <p className="text-center">
            <img src={shield} alt="Shield Icon" width="200" className="content-icons img-fluid" />
          </p>
          <h2 className="text-center">Why Choose Us?</h2>
          <ul className="text-center">
            <li>Fast & Reliable Service & Delivery</li>
            {/* <li>Affordable Pricing</li> */}
            <li>Excellent Customer Service </li>
          </ul>
        </Col>
        <Col lg={6}>
          <p className="text-center">
            <img src={gearIcon} alt="gear Icon" width="200" className="content-icons img-fluid" />
          </p>
          <h2 className="text-center">Our Services</h2>
          <ul className="text-center">
            <li>Sand, dirt and rock delivery</li>
            <li>Commercial construction services</li>
          </ul>
        </Col>
      </Row>
    </Container>
    
    {/* <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["auto", "webp", "avif"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    /> */}
    <hr />
    <Container className="singleCol py-5">
      
      <h2>Contact Us</h2>
      <Row>
        <Col lg={5}>
          <p>
            <strong>Give us a call</strong> <br />(912) 650-0557
          </p>
          <p>
            <strong>Email Us</strong><br />
            <a href="mailto:cjackson@rtjtransportation.com">cjackson@rtjtransportation.com</a>
          </p>
        </Col>
        <Col lg={7}>
          <Form 
          name="contact"
          method="post"
          action="/contact-success/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit="submit"
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contact" />
          <p hidden>
            <label>
                Don’t fill this out: <input name="bot-field" />
            </label>
          </p>
          <Row>
              <Col md={6}>
                  <Form.Group>
                      <Form.Label for="firstName">First Name</Form.Label>
                      <Form.Control 
                          type="text" name="first-name" id="firstName"
                        />
                  </Form.Group>
              </Col>
              <Col md={6}>
              <Form.Group>
                <Form.Label for="lastName">Last Name</Form.Label>
                <Form.Control 
                  type="text" name="last-name"
                  id="lastName"
                  />
              </Form.Group>
              </Col>
          </Row>   

          <Form.Group>
            <Form.Label for="email">Email address</Form.Label>
            <Form.Control 
              required
              type="email" 
              name="email" 
              id="email"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label for="phone">Phone</Form.Label>
            <Form.Control 
              required
              type="text" 
              name="phone" 
              id="phone"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label for="help">How can we help you?</Form.Label>
            <Form.Control 
              as="textarea" 
              name="message"
              rows="3"
              id="help"
              />
          </Form.Group>
          <Button type="submit" variant="outline-dark">Submit</Button>
        </Form>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default IndexPage
